<template>
    <DataTable
        :value="displayWidgets"
        :loading="loading"
        v-model:expandedRows="expandedRows" dataKey="pk"

    >
        <Column headerStyle="width: 40px" bodyStyle="width: 40px">
            <template #body="props">
                <Button
                    :icon="isExpandedIcon(props.data)"
                    class="p-button-text p-button-rounded"
                    @click="toggleExpansion(props.data)"
                    v-if="hasDetail(props.data)"
                />
            </template>
        </Column>
        <Column header="Parameter Name" field="mhp.parameter.name">
            <template #body="props">
                <span v-show="props.data.flags & 0x20">&gt;&nbsp;</span>
                <span class="info">
                    <i class="fa-duotone fa-question-circle" v-if="props.data.description"
                        v-tooltip="props.data.description"
                    ></i>
                </span>
                {{ props.data.name }}
            </template>
        </Column>
        <Column>
            <template #body="props">
                <component :is="comp4Value(props.data)"
                    :pk="props.data.pk"
                    :options="optionsFor(props.data)"
                    :header="props.data.header"
                    :value="valueFor(props.data)"
                    :modelValue="valueFor(props.data)"
                    :forms="props.data.moduleForms"
                    :projectId="projectId"
                    showButtons :min="props.data.min" :max="props.data.max"
                    @update:modelValue="changeValue($event, props.data)"
                    @add-choice="$emit('add-choice', props.data)"
                    @add-instance="addInstance ($event, props.data.pk)"
                />
            </template>
        </Column>
        <Column field="varType.name" >
        </Column>
        <Column field="sec">
            <template #body="props">
                {{ secAsText (props.data.sec) }}
            </template>
        </Column>
        <template #expansion="props">
            <Accordion :multiple="true" style="flex-grow: 1" v-if="props.data.varType.id == 244">
                <AccordionTab v-for="(obj, idx) in valueFor(props.data)" :key="obj.IUID.value">
                    <template #header>
                        <div class="p-d-flex p-jc-between">
                            <div>
                                <Button icon="fa-duotone fa-sort-circle-up"   class="p-button-rounded p-button-text" :disabled="idx == 0" @click.stop="move(props.data, -1, idx)" />
                                <span>{{ props.data.moduleForms [0].name}} {{ 1 + idx }}</span>
                                <Button icon="fa-duotone fa-sort-circle-down" class="p-button-rounded p-button-text" :disabled="idx == (valueFor(props.data).length -1)" @click.stop="move(props.data, 1, idx)" />
                            </div>
                            <div>
                                <Button icon="fa-duotone fa-trash" class="p-button-rounded p-button-text p-button-danger" @click.prevent.stop="remove(props.data, idx)" />
                            </div>
                        </div>
                    </template>
                    <NestedParameterTable
                        :secLevel="secLevel"
                        :spec="props.data.moduleForms [0]"
                        :values="obj"
                        :loading="false"
                        :secLevels="secLevels"
                        :projectId="projectId"
                        @update:value="changeSubValue($event, props.data, idx)"
                        @add-instance="addInstance ($event, props.data.pk, idx)"
                    />
                </AccordionTab>
            </Accordion>
            <DataTable style="flex-grow: 1" v-else
                :value="valueFor(props.data)"
                @row-reorder="reorderOptions($event, props.data)"
            >
                <Column :rowReorder="true" headerStyle="width: 3rem" bodyStyle="width: 3rem" :reorderableColumn="false">
                </Column>
                <Column header="References">
                    <template #body="iprops">
                        <ValueSelection
                            :modelValue="iprops.data.uid"
                            :options="optionsFor(props.data)"
                            @update:modelValue="changeChoice($event, props.data, iprops.index)"
                        />
                    </template>
                </Column>
            </DataTable>
        </template>
    </DataTable>
</template>

<script>
import { ref, computed }       from 'vue'
import DataTable                    from 'primevue/datatable'
import Column                       from 'primevue/column'
import ValueSelection               from './ValueSelection'
import GroupHeader                  from './GroupHeader'
import InjectHeader                 from './InjectHeader'
import InlineModuleList             from './InlineModuleList'
import ModuleReferenceList          from './ModuleReferenceList'
import NestedParameterTable         from './NestedParameterTable'
import InputNumber                  from 'primevue/inputnumber'
import InputText                    from 'primevue/inputtext'
import InputSwitch                  from 'primevue/inputswitch'
import Accordion                    from 'primevue/accordion'
import AccordionTab                 from 'primevue/accordiontab'

const varType2Component = {
    999: InjectHeader,
      1: InputSwitch,
    241: InputText,
    243: ValueSelection,
    244: InlineModuleList,      // list of inline        objects of other type
    245: ModuleReferenceList,   // list of references to objects of other type
    246: ModuleReferenceList,   // Inject
    249: GroupHeader
    }
/*
    245     : "module-ref-multi-select",
    246     : "module-ref-multi-select",
    248     : "collapse-group",
*/

export default {
    name: "ParameterTable",
    props: {
        secLevel:   {tyoe: Number, default: 0x01},
        spec:       {type: Object,  required: true},
        values:     {type: [Array, Object],  required: true},
        loading:    {type: Boolean, default: true},
        secLevels:  {type: Object,  required: true},
        projectId:  {type: Number,  required: true},
    },
    emits: ["update:value", "move-row", "remove-row", "add-choice", "add-instance"],
    setup (props, {emit}) {
        const expandedRows = ref([]);
        const displayWidgets = computed (() => {
            let result = []
            for (let s of props.spec.widgets) {
                if (((s.sec || 255) & props.secLevel) == props.secLevel)
                    result.push (s)
            }
            return result
        })
        const secAsText = (sec) => {
            const visibility = sec & 0b11101010
            return props.secLevels [visibility] || sec
        }
        const comp4Value = (spec) => {
            const comp = varType2Component [spec.varType.id] || InputNumber
            return comp
        }
        const valueFor = (spec) => {
            const v = props.values [spec.pk] || {value: spec.default, text: ""}
            if (spec.varType.id == 1) { // convert integers to boolean
                v.value = v.value == 1
            }
            return spec.varType.id == 241 ? v.text : v.value
        }
        const optionsFor = (spec) => {
            const o = props.values [spec.pk] || {options: {}}
            const result = (o.options || {}).references || []
            return result
        }
        const changeValue = (newValue, spec) => {
            const current  = props.values [spec.pk]
            const value    = Object.assign (current)
            let changed = false
            if (spec.varType.id == 241) {
                changed     = current.text != newValue
                value.text  = newValue
            } else {
                changed     = current.value != newValue
                value.value = newValue
            }
            if (changed) {
                emit ("update:value", {value, pk: spec.pk})
            }
        }
        const toggleExpansion = (object) => {
            const idx = expandedRows.value.indexOf (object)
            if (idx >= 0) {
                expandedRows.value.splice (idx, 1)
                // we need to reassign it to trigger the update
                expandedRows.value = [...expandedRows.value]
            } else {
                // don't use push -> we need to reassign it to trigger the update
                expandedRows.value = [...expandedRows.value, object]
            }
        }
        const isExpandedIcon = (object) => {
            if (expandedRows.value.indexOf (object) >= 0)
                return "fa-regular fa-minus-square"
            return "fa-regular fa-plus-square"
        }
        const hasDetail = (object) => {
            const vt = object.varType.id
            return ((vt == 244) || (vt == 245) || (vt == 246))
        }
        const changeSubValue = (evt, spec, idx) => {
            let current = props.values [spec.pk]
            let newValue = Object.assign (current, {value: [...current.value]})
            newValue.value [idx] [evt.pk] = evt.value
            emit ("update:value", {value: newValue, pk: spec.pk})
        }
        const changeChoice = (value, spec, idx) => {
            let current = props.values [spec.pk]
            let newValue = Object.assign (current, {value: [...current.value]})
            newValue.value [idx] = {uid: value}
            emit ("update:value", {value: newValue, pk: spec.pk})
        }
        const move = (spec, by, idx) => {
            emit ("move-row", {spec, by, idx})
        }
        const remove = (spec, idx) => {
            emit ("remove-row", {spec, idx})
        }
        const reorderOptions = (evt, spec) => {
            let current = props.values [spec.pk]
            let newValue = Object.assign (current, {value: evt.value})
            emit ("update:value", {value:newValue, pk: spec.pk})
        }
        const addInstance = ({pk, instance}, ppk, idx) => {
            //console.log ("Add instance", pk, ppk, idx)
            if (ppk !== pk) {
                emit ('add-instance', {pk, instance, ppk, index: idx})
            } else {
                emit ('add-instance', {pk, instance})
            }
        }
        return { expandedRows, displayWidgets, reorderOptions,
            secAsText, comp4Value, valueFor, optionsFor, changeValue, changeChoice,
            toggleExpansion, isExpandedIcon, hasDetail, changeSubValue,
            move, remove, addInstance
            }
    },
    components: {DataTable, Column, InputText, InputNumber, InputSwitch,
        ValueSelection, InjectHeader, ModuleReferenceList,
        Accordion, AccordionTab, NestedParameterTable} // eslint-disable-line vue/no-unused-components
}
</script>

<style scoped>
    .info {
        width: 20px;
    }
</style>
