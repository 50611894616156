import session      from './session'
import {reactive}        from "vue"

const projectState = reactive ({projects: []})

const projects = {
    async loadProjects () {
        const r = await session.get (`model-objects/Project/`)
        if (r.data.status) {
            projectState.projects = r.data.objects
        }
        return r
    },
    async loadProjectModules (projectId) {
        return await session.get (`model-objects/Module_in_Project/`,
            {params: {project_id: projectId}})

    },
    async loadHardwareModules (hardwareId) {
        return await session.get (`model-objects/Module_on_Hardware/`,
            {params: {hardware_id: hardwareId}})

    },
    async saveModuleList (moduleList) {
        for (let mip of moduleList) {
            if (mip.id) {
                await session.put (`model-objects/Module_in_Project/${mip.id}/`, mip)
            } else {
                await session.post (`model-objects/Module_in_Project/`, mip)
            }
        }
    },
    async allModules () {
        return await session.get (`model-objects/Module/`)
    },
    async loadProjectInstances (projectId, moduleId) {
        return await session.get (`instances/project/${projectId}/module/${moduleId}/`)
    },
    async loadProjectUsesHardwareInstances (puhId, moduleId) {
        return await session.get (`instances/project/${puhId}/module/${moduleId}/`)
    },
    async loadHardware () {
        return await session.get (`model-objects/Hardware/`)
    },
    async loadInstanceValues (instanceId) {
        return await session.get (`instance/${instanceId}/`)

    },
    async saveInstance (projectId, data) {
        const resp          = await session.put (`instance/${projectId}/`, data)
        const payload       = resp.data
        const newInstance   = payload.instance
        return newInstance
    },
    async newInstance (projectId, moduleId) {
        const resp = await session.get (`instance/defaults/${projectId}/${moduleId}/`)
        return resp
    },
    async loadReferences (projectId, moduleId) {
        const resp = await session.get (`instance/references/${projectId}/${moduleId}/`)
        return resp
    },
    async cleanupUIDs (simulate) {
        const resp = await session.get ("cleanup-uids", {params: {simulate}})
        return resp.data
    },
    async freezeUIDs (simulate) {
        const resp = await session.get ("freeze-uids", {params: {simulate}})
        return resp.data
    },
    async generateCode (hardwareName, puh_id) {
        return await session.request ({ url: `generate-code/?hardware=${hardwareName}&puh=${puh_id}` ,
                           method: "GET",
                           responseType: 'blob'}).then((resp) => {
            const downloadUrl = window.URL.createObjectURL (new Blob ([resp.data]))
            const link        = document.createElement('a')
            link.href         = downloadUrl
            link.setAttribute         ('download', resp.headers ["content-disposition"].split ("=") [1])
            document.body.appendChild (link)
            link.click                ()
            link.remove               ()
        })
    },
    async downloadProjectSettings (pupPk) {
        return await session.request ({ url: `generate-project-settings/?id=${pupPk}` ,
                           method: "GET",
                           responseType: 'blob'}).then((resp) => {
            const downloadUrl = window.URL.createObjectURL (new Blob ([resp.data]))
            const link        = document.createElement('a')
            link.href         = downloadUrl
            link.setAttribute         ('download', resp.headers ["content-disposition"].split ("=") [1])
            document.body.appendChild (link)
            link.click                ()
            link.remove               ()
        })
    },
    async copyHardwareToProject (puh, projectId, name, address) {
        const data = {puh, projectId, name, address}
        const r    = await session.post (`project/copy-hardware/`, data)
        if (r.data && r.data.status) {
            // reload the menu-tree
            await projects.loadProjects ()
            console.log (r.data)
            return r.data
        }
        return {status: false}
    },
    async copyObjectToProjects (source, destinations, newName) {
        const data = {source, destinations, newName}
        const r    = await session.post (`project/copy-object/`, data)
        if (r.data && r.data.status) {
            console.log (r.data)
            return r.data
        }
        return {status: false}
    },
}

export default projects
export {projects, projectState}
