import { createRouter, createWebHistory } from "vue-router"
import UserLogin            from "@/views/auth/UserLogin"
import UserLogout           from "@/views/auth/UserLogout"
import PasswordReset        from "@/views/auth/PasswordReset"
import ChangePassword       from "@/views/auth/ChangePassword"
import PasswordResetConfirm from "@/views/auth/PasswordResetConfirm"
import UserRegister         from "@/views/auth/UserRegister"
import VerifyEmail          from "@/views/auth/VerifyEmail"
import ModelDefinition      from "@/views/ModelDefinition"
import ObjectEditView       from "@/views/ObjectEditView"
import CustomerTreeView     from "@/views/isew/CustomerTreeView"
import LiceneKeyList        from "@/views/isew/LiceneKeyList"
import ProjectDetail        from "@/views/isew/ProjectDetail"
import ProjectUsesHardwareDetail        from "@/views/isew/ProjectUsesHardwareDetail"
import ProjectNew           from "@/views/isew/ProjectNew"
import ProjectAdmin         from "@/views/isew/ProjectAdmin"
import DashBoard            from "@/views/DashBoard"
import Auth                 from "@/store/auth"
import TranslationUpload    from "@/views/isew/TranslationUpload"
import TranslationTranslate from "@/views/isew/TranslationTranslate"
import TranslationDownload  from "@/views/isew/TranslationDownload"
import DataCenterSetup      from "@/views/isew/DataCenterSetup"
import DataCenterDownload   from "@/views/isew/DataCenterDownload"
import HardwareConfig       from "@/views/isew/HardwareConfig"
import SecurityResponse     from "@/views/isew/SecurityResponse"

const requireUnauthenticated = () => {
    if (Auth.isLoggedIn ()) {
        return { path: "/"}
    }
}
const requireAuthenticated = () => {
    if (! Auth.isLoggedIn ())
        return { name: "login"}
}

const redirectLogout = async () => {
    await Auth.logout ()
    return { path: "/"}
}

const queryAsParam = (queryParas) => {
    return (route) => {
        const result = route.params
        for (const n of queryParas) {
            const v = route.query [n]
            if (v !== undefined)
                result [n] = v
        }
        return result
    }
}

const routes = [
    {
        path:       "/",
        component:  DashBoard,
        name:       "dashboard"
    },
    {
        path:       "/customers",
        component:  CustomerTreeView,
        props:      true,
        name:       "customer-tree"
    },
    {
        path:       "/module/:modelName",
        component:  ModelDefinition,
        props:      true,
        name:       "module-definition"
    },
    {
        path:       "/object/:modelName/:id",
        component:  ObjectEditView,
        props:      queryAsParam (["formName", "asNew"]),
        name:       "object-edit"
    },
    {
        path:       "/licence-keys/group/:id",
        component:  LiceneKeyList,
        props:      route => ({type: "Licence_Key_Group", id: Number (route.params.id)}),
        name:       "licence-keys-group"
    },
    {
        path:       "/licence-keys/customer/:id",
        component:  LiceneKeyList,
        props:      route => ({type: "Customer", id: Number (route.params.id)}),
        name:       "licence-keys-customer"
    },
    {
        path:       "/licence-keys/ecu/:id",
        component:  LiceneKeyList,
        props:      route => ({type: "ECU", id: Number (route.params.id)}),
        name:       "licence-keys-ecu"
    },
    {
        path:       "/project-detail/:id",
        component:  ProjectDetail,
        props:      route => ({id: Number (route.params.id)}),
        name:       "project-detail"
    },
    {
        path:       "/hardware-uses-project/:id/:pName/:lName/:hardwareId",
        component:  ProjectUsesHardwareDetail,
        props:      route => ({pName: route.params.pName, lName: route.params.lName, hardwareId: Number (route.params.hardwareId), id: Number (route.params.id)}),
        name:       "hardware-uses-project"
    },
    {
        path:       "/project-new",
        component:  ProjectNew,
        name:       "project-new"
    },
    {
        path:       "/project-admin",
        component:  ProjectAdmin,
        name:       "project-admin"
    },

    {
        path:       "/translation/upload",
        component:  TranslationUpload,
        name:       "translation-upload"
    },
    {
        path:       "/translation/translate",
        component:  TranslationTranslate,
        name:       "translation-translate"
    },
    {
        path:       "/translation/download",
        component:  TranslationDownload,
        name:       "translation-download"
    },

    {
        path:       "/data-center/setup",
        component:  DataCenterSetup,
        name:       "datacenter-setup"
    },
    {
        path:       "/data-center/download",
        component:  DataCenterDownload,
        name:       "datacenter-download"
    },
    {
        path:       "/hardware-config/:id",
        component:  HardwareConfig,
        props:      route => ({id: Number (route.params.id)}),
        name:       "hardware-config"
    },
    {
        path:       "/security-response/",
        component:  SecurityResponse,
        name:       "security-response"
    },
    

    /* auth and registration url handling */
    {
        path: '/auth/login',
        name: "login",
        component: UserLogin,
        beforeEnter: requireUnauthenticated,
    },
    {
        path: '/auth/logout',
        name: "logout",
        component: UserLogout,
        beforeEnter: redirectLogout,
    },
    {
        path: '/auth/change-password',
        name: "change_password",
        component: ChangePassword,
        beforeEnter: requireAuthenticated
    },
    {
        path: '/auth/password-reset',
        name: "password_reset",
        component: PasswordReset,
    },

    {
        path: '/password-reset/:uid/:token',
        component: PasswordResetConfirm,
    },
    {
        path: '/signup/register',
        name: "register",
        component: UserRegister,
    },
    {
        path: '/register/:key',
        component: VerifyEmail,
    },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
