<template>
    <div>{{ header }}</div>
</template>

<script>

export default {
    props: { header: {type: String, required: true}}
}
</script>

<style scoped>
    div {
        padding-top: 1.5em;
        color: red;
        font-size: 1.2rem;
    }
</style>
